/** @jsx jsx */

import { css } from "@emotion/core"
import { jsx } from "theme-ui"

function Circle({ circle, handleMouseEnter, handleClick, hasFocus }) {
  const fill = circle._attributes.id.startsWith("placeholder")
    ? "transparent"
    : `url(#${circle._attributes.id})`

  return (
    <g
      onMouseEnter={handleMouseEnter}
      onClick={() => {
        handleClick(circle._attributes.id)
      }}
      css={css`
        circle {
          transition: r 0.2s;
        }
      `}
    >
      <circle
        fill={hasFocus ? fill : circle._attributes.fill}
        cx={circle.x}
        cy={circle.y}
        r={hasFocus ? 100 : circle.radius}
        opacity={hasFocus ? 1 : circle._attributes.opacity}
        stroke={circle._attributes.stroke}
        strokeWidth={hasFocus ? 10 : circle._attributes["stroke-width"]}
      />
    </g>
  )
}

export default Circle
