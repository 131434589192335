/** @jsx jsx */

import { jsx } from "theme-ui"

/**
 * Non-interactive illustration elements
 */
function IlloElements() {
    return (
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity=".6">
            <g id="elements" transform="translate(-6 -58.473)" fill="#2E2729">
                <path id="Path-Copy-2" transform="rotate(25 79 199.973)" d="M32.946 118.473L128 278.113l-5.612 3.36L30 120.884z" />
                <path id="Path-Copy-3" transform="rotate(150 320.076 333.08)" d="M130.475 62.973l387.1 538.492-5.638 1.722L122.576 67.079z" />
                <path id="Path-Copy-4" transform="rotate(65 463.845 321.096)" d="M314.564 112.596l304.78 415.67-4.439 1.33-306.56-413.83z" />
            </g>
        </g>
    )
}

export default IlloElements




