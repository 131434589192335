/** @jsx jsx */

import { jsx } from "theme-ui"

const circles = []

for (let i = 0; i < 20; i++) {
    circles.push({
        cx: Math.random() * 2000,
        cy: Math.random() * 1000,
        r: Math.random() * 20 + 10,
        fill: `hsl(${Math.random() * 40 + 135}, 35%, 69%, .60)` // Variations on the blue-green
    })
}

function Background() {
    return (
        <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ position: "absolute", left: 0, zIndex: -1000 }}
        >
            <path
                style={{
                    fill: `hsl(175, 35%, 69%, .60)`, // Blue-green
                    stroke: "none",
                    fillOpacity: 0.6
                }}
                d="M 500,-100 2000,1500 2000,1500 150,-100 Z"
                id="path35" />
            <path
                style={{
                    fill: `hsl(175, 45%, 59%, .60)`, // Blue-green
                    stroke: "none",
                    fillOpacity: 0.6
                }}
                d="M 0,500 2000,-100 2000,100 0,1000 Z"
                id="path35" />
            {
                circles.map((c, i) => <circle key={i} {...c} />)
            }
        </svg>
    )
}

export default Background
