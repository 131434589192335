import { StaticQuery, graphql } from "gatsby"

import React from "react"

function SVGDefs({ artists }) {
  return (
    <defs>
      {artists.map(artist => (
        <pattern
          key={artist.key}
          id={artist.key}
          width="100%"
          height="100%"
          viewBox="0 0 300 300"
        >
          <image width="300" height="300" href={artist.url}></image>
        </pattern>
      ))}
    </defs>
  )
}

export default function () {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            nodes {
              id
              frontmatter {
                title
                key
                featuredImage
              }
              fields {
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const artists = data.allMarkdownRemark.nodes
          .map(node => {
            return {
              key: node.frontmatter.key,
              url: node.frontmatter.featuredImage,
            }
          })
          .filter(artist => artist.key)
          .filter(artist => {
            return artist.url !== ""
          })

        return <SVGDefs artists={artists} />
      }}
    />
  )
}
