import React, { useState } from "react"
import { StaticQuery, graphql, navigate } from "gatsby"

import Background from "../components/background"
import Circle from "../components/circle"
import IlloElements from "../components/illo-elements"
import Layout from "../components/layout"
import SVGContainer from "../components/svg-container"
import SVGDefs from "./svg-defs"
import { convertSvg } from "../utils/convert-svg"
import { svg } from "../utils/svg"

const circles = convertSvg(svg)

function Home({ artistMap }) {
  const [focussedId, setFocussedId] = useState("")
  const handleCircleEnter = id => {
    const { location, imageUrl } = artistMap[id]
    if (location && imageUrl) {
      setFocussedId(id)
    }
  }
  const handleIlloClick = id => {
    const { location, imageUrl } = artistMap[id]
    if (location && imageUrl) {
      navigate(location)
    }
  }
  return (
    <Layout>
      <SVGContainer>
        <SVGDefs />
        <IlloElements />
        <g>
          {circles.map(circle => {
            const { id } = circle._attributes
            // const hasFocus = (
            //     id === "susan-sgorbati"
            //     || id === "jake-meginsky"
            //     || id === "mina-nishimura"
            // )
            const hasFocus = id === focussedId
            return (
              <Circle
                key={id}
                handleMouseEnter={() => {
                  // console.log(id, circle._attributes["stroke-width"], circle._attributes.stroke)
                  handleCircleEnter(id)
                }}
                handleClick={handleIlloClick}
                hasFocus={hasFocus}
                circle={circle}
              />
            )
          })}
        </g>
      </SVGContainer>
      <Background />
    </Layout>
  )
}

export default function () {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            nodes {
              id
              frontmatter {
                title
                key
                featuredImage
              }
              fields {
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const artistMap = data.allMarkdownRemark.nodes.reduce((map, artist) => {
          const newMap = map
          newMap[artist.frontmatter.key] = {
            location: artist.fields.slug,
            imageUrl: artist.frontmatter.featuredImage,
          }
          return newMap
        }, {})

        return <Home artistMap={artistMap} />
      }}
    />
  )
}
