/*
hsl(325deg 96% 56%)

change stroke to 15 on hover
*/

// For orange stroke
const orange = `hsl(15deg 100% 42%)`
const orange2 = `#B34F22`

// stroke for greens
const green = `hsl(157deg 58% 47%)`

// stroke for purple
const purple = `hsl(325deg 69% 38%)`

export const svg = `
<g id="circles">
    <circle id="jake-meginsky" fill="${orange2}" stroke="${orange2}" opacity="0.6" cx="506.5" cy="141.5" r="102.5"></circle>
    <circle id="hasan-kayle" stroke-width="10" fill="#F4A40C" stroke="${orange}" opacity="0.6" cx="208.5" cy="409.5" r="77.5"></circle>
    <circle id="mina-nishimura" fill="#F4A40C" stroke="${orange}" stroke-width="0" opacity="0.6" cx="262" cy="161" r="122"></circle>
    <circle id="souleymane-badolo" fill="#ACB67B" stroke="${green}" stroke-width="0" opacity="0.6" cx="400.5" cy="153.5" r="65.5"></circle>
    <circle id="susan-sgorbati" fill="${orange2}" stroke="${orange2}" opacity="0.6" cx="466" cy="279" r="83"></circle>

    <circle id="climer-haas" stroke="${orange}" stroke-width="10" fill="#F4A40C" opacity="0.6" cx="359" cy="237" r="46"></circle>

    <circle id="katie-martin" stroke="${purple}" stroke-width="10" fill="#9F6C8A" opacity="0.6" cx="29" cy="291" r="24"></circle>
    <circle id="joe-poulson" stroke="#2E2729" fill="#2E2729" cx="93.5" cy="206.5" r="11.5"></circle>
    <circle id="nikolaus-tsocanos" stroke="${orange2}" fill="${orange2}" opacity="0.6" cx="118.5" cy="277.5" r="15.5"></circle>
    <circle id="finn-murphy" stroke="${orange2}" fill="${orange2}" opacity="0.6" cx="233.5" cy="280.5" r="18.5"></circle>
    <circle id="lydia-chrisman" stroke="#2E2729" fill="#2E2729" opacity="0.6" cx="517.5" cy="368.5" r="45.5"></circle>
    <circle id="cori-olinghouse" stroke="#9F6C8A" fill="#9F6C8A" opacity="0.6" cx="583.5" cy="431.5" r="65.5"></circle>
    <circle id="nicole-daunic" stroke="#2E2729" fill="#2E2729" opacity="0.6" cx="163" cy="464" r="29"></circle>
    <circle id="zornitsa-stoyanova" fill="#ACB67B" stroke="${green}" stroke-width="10" opacity="0.6" cx="364" cy="439" r="118"></circle>
    <circle id="leah-morrison" stroke="#F4A40C" fill="#F4A40C" opacity="0.6" cx="90" cy="391" r="18"></circle>
    <circle id="tony-orrico" stroke="#ACB67B" fill="#ACB67B" opacity="0.6" cx="240" cy="40" r="40"></circle>
    <circle id="eva-schmidt" stroke="${orange}" stroke-width="10" fill="${orange2}" opacity="0.6" cx="158.5" cy="151.5" r="42.5"></circle>
    <circle id="kota-yamazaki" stroke="#ACB67B" fill="#ACB67B" opacity="0.6" cx="419" cy="448" r="29"></circle>
    <circle id="elliot-caplan" stroke="#ACB67B" fill="#ACB67B" opacity="0.6" cx="262" cy="300" r="29"></circle>
</g>
`
