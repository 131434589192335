/** @jsx jsx */
import { jsx } from "theme-ui"

function Container({ children }) {
  return (
    <svg
      width="80vmin"
      height="80vmin"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  )
}

export default Container
