var convert = require('xml-js');

const WIDTH = 649
const HEIGHT = 558

const convertCx = numString => parseInt(numString) / WIDTH * 100 + "%"
const convertCy = numString => parseInt(numString) / HEIGHT * 100 + "%"
const convertRadius = r => r / HEIGHT * 70 + "vmin" // This 70 is a magic tuning number 🤷🏻‍♀️

export const convertSvg = svgString => {
  const result = JSON.parse(
    convert.xml2json(svgString, { compact: true, spaces: 4 })
  )
  const circles = result.g.circle
    .map(c => ({
      ...c,
      x: convertCx(c._attributes.cx),
      y: convertCy(c._attributes.cy),
      radius: convertRadius(c._attributes.r)
    }))

  return circles
}
